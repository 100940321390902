#auraglow {
  width: 100%;
  height: 50%;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  position: relative;
  background: linear-gradient(40deg, #ee7752, #e73c7e, #23a6d5, #fff, #23d5ab, red);
	background-size: 400% 400%;
	animation: gradient 8s ease-in-out infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 50% 0%;
	}
}
